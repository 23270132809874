import axios from 'axios';
import indexSystem from '../commons/indexSystem'
// import store from '../store/index'
import {Toast} from "vant";
// 示例接口
export const requestNoToken = async function (options) {
    options.url = indexSystem.ApiUrl + options.url
    options.method = 'post'
    options.headers = { 'Content-Type': 'application/json;charset=utf-8'}
    // eslint-disable-next-line no-self-assign
    options.data = options.data

    return new Promise((resolve) => {
        axios(options).then(res => {
            //  这里主要根据后来返回的数据作判断，请根据实际情况
            if (res.data.code === 0) {
                // MsgMessage(res.data.msg)
                resolve(res.data)
            } else {
                // Toast.fail(res.data.message);
            }
        })
    })
}
