import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// 持久化存储vuex 默认 localStorage
import createPersistedState from "vuex-persistedstate"
export default new Vuex.Store({
  state: {
    // 用章
    userLoginData: {
      token: "",
      applySubsystemID:'',
      type:''
    },
    // 审批流程
    approvalProcess:{
      sprList:'',
      csrList:''
    }
  },
  mutations: {
    // 存审批人
    approvalProcessSprList: function(state, data) {
      state.approvalProcess.sprList = data.sprList;
    },
    // 存抄送人
    approvalProcessCsrList: function(state, data) {
      state.approvalProcess.csrList = data.csrList;
    },
    // 存token
    saveUserLoginData: function(state, data) {
      state.userLoginData.token = data.token;
    },
    // 存用章详情的id
    saveUserID: function(state, data) {
      state.userLoginData.applySubsystemID = data.applySubsystemID;
    },
    // 存type
    saveUserType: function(state, data) {
      state.userLoginData.type = data.type;
    },
    // 取token
    //   清空token方法    store.commit('deleteUserLoginData')
    deleteUserLoginData: function(state) {
      state.userLoginData.token = "";
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
