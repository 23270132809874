import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 微信js-sdk
// import wx from 'weixin-js-sdk';
// Vue.prototype.$wx = wx;
Vue.config.productionTip = false
import Vant from 'vant';
import 'vant/lib/index.css';
import  'lib-flexible'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'oBvDtR6nzWtVchkY4cLHtnah1VVZQKRK' // 这个地方是官方提供的ak密钥
})
import VueAMap from 'vue-amap';
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // 高德key
  key: '6b8d48xxxxxxxxxxxb659b', // 官网申请
  // 插件集合 （插件按需引入）
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MarkerClusterer','AMap.AMapUI','AMapUI.loadUI'],
  v: '1.4.15', // 我也不知道为什么要写这个，不写项目会报错，而且我随便写的，跟我下载的版本对应不了
  uiVersion: '1.0.11' // ui版本号，也是需要写
})
Vue.use(Vant);
import { requestNoToken } from "./request/index";
Vue.prototype.$request = { requestNoToken };
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
